<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout pt-4 wrap justify-center>
      <v-flex xs12 sm12 md12>
        <span style="font-size:18px;font-weight:500">Key Partners</span>
      </v-flex>
    </v-layout>
    <v-layout pt-4 wrap justify-center>
      <v-flex xs12 sm12 md5>
        <v-text-field
          ref="partnerName"
          v-model="partnerName"
          prepend-icon="mdi-map-marker-outline"
          label="Partner Name"
          color="#26af82"
          required
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm12 md5>
        <input ref="uploadPicture" accept="image/*" type="file" @change="uploadPicture" />
      </v-flex>
      <v-flex xs12 sm12 md10 text-left>
        <span style="font-size:16px;font-weight:500;">Description</span>
      </v-flex>
      <v-flex xs12 sm12 md10>
        <vue-editor
          v-model="description"
          ref="description"
          prepend-icon="mdi-format-align-justify"
          color="#26af82"
        ></vue-editor>
      </v-flex>
    </v-layout>
    <v-layout px-2 pb-12 wrap justify-center>
      <v-flex xs12 sm12 md5>
        <v-btn
          @click="addKeyPartner"
          small
          dark
          :ripple="false"
          class="text-capitalize"
          color="#26af82"
          width="250"
        >
          <span>Add Key Partner</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueElementLoading,
    VueEditor
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      partnerName: null,
      description: null,
      picture: null,
      logoSts: false,
      newPartnerId: null
    };
  },
  methods: {
    addKeyPartner() {
      var data = {};
      data["name"] = this.partnerName;
      data["description"] = this.description;
      data["projectid"] = this.id;
      axios({
        method: "POST",
        url: "/partner/add",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          if (response.data.status) {
            this.partnername = null;
            this.partnerdescription = null;
            this.newPartnerId = response.data.id;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            if (this.logosts) {
              this.updatePicture();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    uploadPicture(item) {
      this.picture = item.target.files[0];
      if (item.target.files[0].size > 5242880) {
        this.picture = null;
        this.msg = "File Should be less than 5MB";
        this.showSnackBar = true;
        return;
      } else {
        this.logoSts = true;
      }
    },

    updatePicture() {
      let formData = new FormData();
      formData.append("logo", this.picture);
      formData.append("id", this.newPartnerId);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/partner/logo",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    }
  }
};
</script>