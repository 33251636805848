<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout pt-4 wrap justify-center>
      <v-flex xs12 sm12 md12>
        <span style="font-size:18px;font-weight:500">Investment Securities</span>
      </v-flex>
    </v-layout>
    <v-layout pt-4 wrap justify-center>
      <v-flex xs12 sm12 md10>
        <v-text-field
          ref="name"
          v-model="name"
          prepend-icon="mdi-map-marker-outline"
          label="Name"
          color="#26af82"
          required
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout px-2 pb-12 wrap justify-center>
      <v-flex xs12 sm12 md5>
        <v-btn small dark :ripple="false" class="text-capitalize" color="#26af82" width="250">
          <span>Add Target Metrics</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      name: null
    };
  }
};
</script>