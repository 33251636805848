<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout pt-4 wrap justify-center>
      <v-flex xs12 sm12 md12>
        <span style="font-size:18px;font-weight:500">Investment Securities</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md10 v-for="(item,i) in investmentSecurities" :key="i">
        <v-layout wrap justify-center>
          <v-flex xs12 sm12 md4>
            <v-text-field
              ref="name"
              v-model="item.name"
              prepend-icon="mdi-cash-multiple"
              label="Name"
              color="#26af82"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md4>
            <v-select
              v-model="item.investmentType"
              :items="investmentTypes"
              item-text="name"
              item-value="_id"
              color="#26af82"
              item-color="#26af82"
              label="Investment Type"
              prepend-icon="mdi-hexagon-multiple"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm12 md4>
            <v-text-field
              ref="name"
              v-model="item.estimatedReturns"
              prepend-icon="mdi-briefcase"
              label="Estimated Returns"
              color="#26af82"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12 text-left>
            <span style="font-size:14px;font-weight:500">Short Description</span>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <vue-editor
              v-model="item.shortDescription"
              ref="investmentSummary"
              prepend-icon="mdi-format-align-justify"
              color="#26af82"
            ></vue-editor>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-end>
          <v-flex xs12 sm12 md6>
            <!-- <v-btn small text dark :ripple="false" class="text-capitalize" color="blue" width="250">
              <span>Update Investment Security</span>
            </v-btn>-->
          </v-flex>
          <v-flex xs12 sm12 md6>
            <v-btn
              @click="investmentSecurities.splice(i, 1)"
              small
              text
              dark
              :ripple="false"
              class="text-capitalize"
              color="red"
              width="250"
            >
              <span>Delete Investment Security</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 sm12 md10 text-left>
        <v-btn
          @click="addInvestmentSecurities"
          small
          text
          dark
          :ripple="false"
          class="text-capitalize"
          color="#26af82"
          width="250"
        >
          <span>Add Investment Security</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout px-2 pb-12 wrap justify-center>
      <v-flex xs12 sm12 md5>
        <v-btn
          @click="updateInvestmentSecurities"
          small
          dark
          :ripple="false"
          class="text-capitalize"
          color="#26af82"
          width="250"
        >
          <span>Update Investment Securities</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueElementLoading,
    VueEditor
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      investmentSecurities: [],
      name: null,
      investmentTypes: ["Real Number", "Number", "Character"]
    };
  },
  methods: {
    addInvestmentSecurities() {
      this.investmentSecurities.push({
        name: null,
        investmentType: null,
        estimatedReturns: null,
        shortDescription: null
      });
    },
    updateInvestmentSecurities() {
      for (var i = 0; i < this.investmentSecurities.length; i++) {
        if (
          !this.investmentSecurities[i].name ||
          !this.investmentSecurities[i].investmentType ||
          !this.investmentSecurities[i].estimatedReturns ||
          !this.investmentSecurities[i].shortDescription
        ) {
          this.msg = "Please Validate  Input";
          this.showSnackBar = true;
        } else {
          this.appLoading = true;
          var data = {};
          data["data"] = this.investmentSecurities;
          data["projectid"] = this.id;
          axios({
            method: "POST",
            url: "/projectsecurity/addall",
            headers: {
              "x-auth-token": localStorage.getItem("token")
            },
            data: data
          })
            .then(response => {
              if (response.data.status) {
                this.appLoading = false;
                this.msg = response.data.msg;
                this.showSnackBar = true;
              } else {
                this.appLoading = false;
                this.msg = response.data.msg;
                this.showSnackBar = true;
              }
            })
            .catch(err => {
              this.appLoading = false;
              this.ms = err;
              this.msg = "Server Unreachable";
              this.showSnackBar = true;
            });
        }
      }
    }
  }
};
</script>