<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout pt-4 wrap justify-center>
      <v-flex xs12 sm12 md12>
        <span style="font-size:18px;font-weight:500">Terms & Conditions</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md10 v-for="(item,i) in termsAndConditions" :key="i">
        <v-layout wrap justify-center>
          <v-flex xs12 sm12 md12>
            <v-textarea
              ref="title"
              v-model="item.title"
              prepend-icon="mdi-cash-multiple"
              label="Title"
              color="#26af82"
              rows="2"
              required
            ></v-textarea>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-textarea
              ref="body"
              v-model="item.body"
              prepend-icon="mdi-cash-multiple"
              label="Body"
              color="#26af82"
              required
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-end>
          <v-flex xs12 sm12 md6></v-flex>
          <v-flex xs12 sm12 md6 text-right>
            <v-btn
              @click="termsAndConditions.splice(i, 1)"
              small
              text
              dark
              :ripple="false"
              class="text-capitalize"
              color="red"
              width="250"
            >
              <span>Delete Terms & Conditions</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 sm12 md10 text-left>
        <v-btn
          @click="addprojectFAQ"
          small
          text
          dark
          :ripple="false"
          class="text-capitalize"
          color="#26af82"
          width="250"
        >
          <span>Add Terms & Conditions</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout px-2 pb-12 wrap justify-center>
      <v-flex xs12 sm12 md5>
        <v-btn
          @click="updateprojectFAQ"
          small
          dark
          :ripple="false"
          class="text-capitalize"
          color="#26af82"
          width="250"
        >
          <span>Update Terms & Conditions</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      termsAndConditions: [],
      name: null
    };
  },
  methods: {
    addprojectFAQ() {
      this.termsAndConditions.push({
        title: null,
        body: null
      });
    },
    updateprojectFAQ() {
      for (var i = 0; i < this.termsAndConditions.length; i++) {
        if (
          !this.termsAndConditions[i].name ||
          !this.termsAndConditions[i].description
        ) {
          this.msg = "Please Validate  Input";
          this.showSnackBar = true;
        } else {
          this.appLoading = true;
          var data = {};
          data["data"] = this.termsAndConditions;
          data["projectid"] = this.id;
          axios({
            method: "POST",
            url: "/projectconditions/addall",
            headers: {
              "x-auth-token": localStorage.getItem("token")
            },
            data: data
          })
            .then(response => {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            })
            .catch(err => {
              this.appLoading = false;
              this.ms = err;
              this.msg = "Server Unreachable";
              this.showSnackBar = true;
            });
        }
      }
    }
  }
};
</script>