<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout pa-12 wrap justify-center>
      <v-flex xs12 sm12 md12 text-center>
        <v-layout px-2 pt-2 wrap justify-center>
          <v-flex xs12 sm12 md10>
            <span style="font-size:18px;font-weight:500;">Add Project Picture</span>
            <br />
            <span style="font-size:12px;font-weight:400;">Image should be less than 5MB</span>
          </v-flex>
        </v-layout>
        <v-layout pt-4 wrap px-2 justify-center>
          <v-flex xs12 sm12 md6 v-if="projectLogo">
            <img v-bind:src="baseURL+'/u/'+projectLogo" height="auto" width="20%" />
          </v-flex>
          <v-flex xs12 sm12 md6 text-center>
            <input ref="uploadPicture" accept="image/*" type="file" @change="uploadPicture" />
          </v-flex>
          <v-flex py-4 xs12 sm12 md12>
            <v-btn
              @click="updatePicture"
              small
              dark
              :ripple="false"
              class="text-capitalize"
              color="#26af82"
              width="250"
            >Update Project Picture</v-btn>
          </v-flex>
        </v-layout>
        <v-layout px-2 pt-2 wrap justify-center>
          <v-flex xs12 sm12 md10>
            <span style="font-size:18px;font-weight:500;">Add Project Video</span>
            <br />
            <!-- <span style="font-size:12px;font-weight:400;">Video should be less than 10MB</span> -->
          </v-flex>
        </v-layout>
        <v-layout pt-4 wrap px-2 justify-center>
          <v-flex xs12 sm12 md6 v-if="projectVideo">
            <video width="320" height="240" controls>
              <source v-bind:src="baseURL+'/u/'+projectVideo" type="video/mp4" />
            </video>
          </v-flex>
          <v-flex xs12 sm12 md6 text-center>
            <input ref="uploadPicture" accept="image/*" type="file" @change="uploadVideo" />
          </v-flex>
          <v-flex py-4 xs12 sm12 md12>
            <v-btn
              @click="updateVideo"
              small
              dark
              :ripple="false"
              class="text-capitalize"
              color="#26af82"
              width="250"
            >Update Project Video</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  props: ["projectData"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      picture: null,
      projectLogo: null,
      projectVideo: null,
      url: null
    };
  },
  methods: {
    uploadPicture(item) {
      this.picture = item.target.files[0];
      if (item.target.files[0].size > 5242880) {
        this.picture = null;
        this.msg = "File Should be less than 5MB";
        this.showSnackBar = true;
        return;
      }
    },
    uploadVideo(item) {
      this.video = item.target.files[0];
      // if (item.target.files[0].size > 10485760) {
      //   this.video = null;
      //   this.msg = "File Should be less than 10MB";
      //   this.showSnackBar = true;
      //   return;
      // }
    },
    updatePicture() {
      let formData = new FormData();
      formData.append("logo", this.picture);
      formData.append("id", this.id);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/project/logo",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    updateVideo() {
      let formData = new FormData();
      formData.append("media", this.video);
      formData.append("id", this.id);
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/project/media",
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token")
        },
        data: formData
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.ms = err.response;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    }
  }
};
</script>