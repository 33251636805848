<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="appConfirmation" width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          <span style="font-size:16px;font-weight:600">
            Would you like to
            <span v-if="mode=='Draft'">Publish</span>
            <span v-else>Draft</span>
            this Project ?
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#00a972" text @click="appConfirmation = false">Cancel</v-btn>
          <v-btn color="red" text @click="projectStatus">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout pt-4 wrap justify-center>
      <v-flex xs12 sm12 md12>
        <span style="font-size:18px;font-weight:500">Change Project Status</span>
      </v-flex>
    </v-layout>
    <v-layout px-2 pb-12 wrap justify-center>
      <v-flex xs12 sm12 md10>
        <v-btn
          @click="appConfirmation=true"
          text
          dark
          :ripple="false"
          class="text-capitalize"
          color="red"
          width="250"
        >Click Here To Publish Project</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      name: null,
      appConfirmation: false,
      mode: "Draft"
    };
  },
  methods: {
    projectStatus() {
      this.appConfirmation = false;
      this.appLoading = true;
      var data = {};
      data["id"] = this.id;
      if (this.mode == "Draft") {
        data["mode"] = "Publish";
      } else {
        data["mode"] = "Draft";
      }
      axios({
        method: "POST",
        url: "/project/status",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    }
  }
};
</script>