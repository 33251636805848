<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card outlined tile style="min-width:80vw;">
          <v-layout wrap justify-center>
            <v-flex xs12 sm12 md12 text-center>
              <ProjectDetails @stepper="winStepper" v-bind:ProjectData="ProjectData" />
              <ProjectMedia
                v-if="nextComponent>0"
                @stepper="winStepper"
                v-bind:ProjectData="ProjectData"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import ProjectDetails from "./AddProject/projectDetails";
import ProjectMedia from "./AddProject/projectMedia";

export default {
  components: {
    VueElementLoading,
    ProjectDetails,
    ProjectMedia
  },
  data() {
    return {
      appLoading: false,
      winStep: 0,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      rules: {
        required: value => !!value || "Required",
        number: value => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || "Invalid Input";
        }
      },
      projectId: null,
      nextComponent: 0,
      ProjectData: {}
    };
  },
  methods: {
    winStepper(window_data) {
      this.nextComponent = window_data.nextComponent;
      this.projectId = window_data.projectId;
      this.msg = window_data.msg;
      this.showSnackBar = window_data.showSnackBar;
      if (window_data.updateData) {
        this.getData();
      }
    },
    getData() {
      // alert("hi");
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
.v-input {
  padding-left: 10px;
  padding-right: 10px;
}
</style>