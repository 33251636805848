<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 md12 text-center>
        <!-- <v-card style="min-width:80vw"> -->
        <v-layout pt-4 wrap justify-center>
          <v-flex xs12 sm12 md12>
            <span style="font-size:20px;font-weight:500">Add New Project</span>
          </v-flex>
        </v-layout>
        <v-layout px-2 pt-4 wrap justify-center>
          <v-flex xs12 sm12 md5>
            <v-text-field
              ref="projectName"
              v-model="projectName"
              prepend-icon="mdi-cube-outline"
              label="Project Name"
              color="#26af82"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md5>
            <v-text-field
              ref="address"
              v-model="address"
              prepend-icon="mdi-map-marker-outline"
              label="Address"
              color="#26af82"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md5>
            <v-text-field
              ref="city"
              v-model="city"
              prepend-icon="mdi-map-marker-outline"
              label="City"
              color="#26af82"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md5>
            <v-text-field
              ref="state"
              v-model="state"
              prepend-icon="mdi-map-marker-outline"
              label="State"
              color="#26af82"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md5>
            <v-text-field
              ref="country"
              v-model="country"
              prepend-icon="mdi-map"
              label="Country"
              color="#26af82"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md5>
            <v-select
              v-model="category"
              :items="categories"
              item-text="name"
              item-value="_id"
              color="#26af82"
              item-color="#26af82"
              label="Category"
              prepend-icon="mdi-cube-unfolded"
              multiple
            >
              <template v-slot:selection="{ item }">
                <v-chip small>
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs12 sm12 md5>
            <v-menu
              ref="openDatePicker"
              v-model="openDatePicker"
              :close-on-content-click="false"
              :return-value.sync="openDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="openDate"
                  clearable
                  @click:clear="openDate = null"
                  label="Opening Date"
                  prepend-icon="mdi-calendar"
                  color="#26af82"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="#26af82"
                :max="closeDate"
                v-model="openDate"
                no-title
                scrollable
                required
              >
                <v-spacer></v-spacer>
                <v-btn text color="#26af82" @click="openDatePicker=false">Cancel</v-btn>
                <v-btn color="#26af82" text @click="$refs.openDatePicker.save(openDate)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm12 md5>
            <v-menu
              ref="closeDatePicker"
              v-model="closeDatePicker"
              :close-on-content-click="false"
              :return-value.sync="closeDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="closeDate"
                  clearable
                  @click:clear="closeDate = null"
                  label="Closing Date"
                  prepend-icon="mdi-calendar"
                  color="#26af82"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="#26af82"
                :min="openDate"
                v-model="closeDate"
                no-title
                scrollable
                required
              >
                <v-spacer></v-spacer>
                <v-btn text color="#26af82" @click="closeDatePicker=false">Cancel</v-btn>
                <v-btn color="#26af82" text @click="$refs.closeDatePicker.save(closeDate)">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout px-2 py-4 wrap justify-center>
          <v-flex px-2 xs12 sm12 md10 text-left>
            <span style="font-size:16px;font-weight:500;">Short Description</span>
          </v-flex>
          <v-flex px-2 xs12 sm12 md10>
            <vue-editor
              v-model="shortDescription"
              ref="shortDescription"
              prepend-icon="mdi-format-align-justify"
              color="#26af82"
            ></vue-editor>
          </v-flex>
          <v-flex px-2 xs12 sm12 md10 text-left>
            <span style="font-size:16px;font-weight:500;">Description</span>
          </v-flex>
          <v-flex px-2 xs12 sm12 md10>
            <vue-editor
              v-model="description"
              ref="description"
              prepend-icon="mdi-format-align-justify"
              color="#26af82"
            ></vue-editor>
          </v-flex>
          <v-flex px-2 xs12 sm12 md10 text-left>
            <span style="font-size:16px;font-weight:500;">Investment Summary</span>
          </v-flex>
          <v-flex px-2 xs12 sm12 md10>
            <vue-editor
              v-model="investmentSummary"
              ref="investmentSummary"
              prepend-icon="mdi-format-align-justify"
              color="#26af82"
            ></vue-editor>
          </v-flex>
        </v-layout>
        <v-layout px-2 py-4 wrap justify-center>
          <v-flex xs12 sm12 md10>
            <span style="font-size:18px;font-weight:500;">Financial Details</span>
          </v-flex>
          <v-flex xs12 sm12 md5>
            <v-text-field
              ref="fundingGoal"
              v-model="fundingGoal"
              prepend-icon="mdi-cash-multiple"
              label="Funding Goal"
              color="#26af82"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md5>
            <v-text-field
              ref="minReservation"
              v-model="minReservation"
              prepend-icon="mdi-currency-usd"
              label="Minimum Reservation"
              color="#26af82"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md5>
            <v-text-field
              ref="maxReservation"
              v-model="maxReservation"
              prepend-icon="mdi-currency-usd"
              label="Maximum Reservation"
              color="#26af82"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout px-2 pb-12 wrap justify-center>
          <v-flex xs12 sm12 md5>
            <v-btn
              @click="addProject"
              small
              dark
              :ripple="false"
              class="text-capitalize"
              color="#26af82"
              width="250"
            >
              <span v-if="!id">Add Project</span>
              <span v-else>Update Project Details</span>
            </v-btn>
          </v-flex>
        </v-layout>
        <!-- <ProjectMedia v-if="id" v-bind:id="id" /> -->
        <InvestmentSecurities v-if="id2" v-bind:id="id" />
        <TargetMetrics v-if="id2" v-bind:id="id" />
        <KeyPartners v-if="id2" v-bind:id="id" />
        <FundingDetails v-if="id2" v-bind:id="id" />
        <RiskAndDisclosure v-if="id2" v-bind:id="id" />
        <ProjectFAQ v-if="id2" v-bind:id="id" />
        <TermsAndConditions v-if="id2" v-bind:id="id" />
        <ProjectStatus v-if="id2" v-bind:id="id" />
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
import { VueEditor } from "vue2-editor";
// import ProjectMedia from "./projectMedia";
import InvestmentSecurities from "./investmentSecurities";
import TargetMetrics from "./targetMetrics";
import KeyPartners from "./keyPartners";
import FundingDetails from "./fundingDetails";
import RiskAndDisclosure from "./riskAndDisclosure";
import ProjectFAQ from "./projectFAQ";
import TermsAndConditions from "./termsAndConditions";
import ProjectStatus from "./projectStatus";
export default {
  components: {
    VueElementLoading,
    VueEditor,
    // ProjectMedia,
    InvestmentSecurities,
    TargetMetrics,
    KeyPartners,
    FundingDetails,
    RiskAndDisclosure,
    ProjectFAQ,
    TermsAndConditions,
    ProjectStatus
  },
  data() {
    return {
      id2: false,
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      rules: {
        required: value => !!value || "Required",
        number: value => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || "Invalid Input";
        }
      },
      id: null,
      projectName: null,
      address: null,
      city: null,
      state: null,
      country: null,
      category: [],
      categories: [],
      openDatePicker: false,
      openDate: null,
      closeDatePicker: false,
      closeDate: null,
      shortDescription: null,
      description: null,
      investmentSummary: null,
      fundingGoal: null,
      minReservation: null,
      maxReservation: null
    };
  },
  beforeMount() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/project/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          this.categories = response.data.data;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    addProject2() {
      this.id = "123456789";
      this.$emit("stepper", {
        updateData: true,
        msg: "Hello World",
        showSnackBar: true,
        projectId: this.id
      });
    },
    addProject() {
      this.appLoading = true;
      var data = {};
      if (this.id != null) {
        data["id"] = this.projectData;
      }
      data["name"] = this.projectName;
      data["address"] = this.address;
      data["city"] = this.city;
      data["state"] = this.state;
      data["country"] = this.country;
      data["category"] = this.category;
      data["openDate"] = this.openDate;
      data["closeDate"] = this.closeDate;
      data["shortDescription"] = this.shortDescription;
      data["description"] = this.description;
      data["investmentSummary"] = this.investmentSummary;
      data["fundingGoal"] = this.fundingGoal;
      data["minimumReservation"] = this.minReservation;
      data["maximumReservation"] = this.maxReservation;
      axios({
        method: "POST",
        url: "/project/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          if (response.data.id != undefined) {
            this.$emit("stepper", {
              updateData: true,
              nextComponent: 1,
              msg: response.data.msg,
              showSnackBar: true,
              projectId: response.data.id
            });
          } else {
            this.$emit("stepper", {
              updateData: false,
              nextComponent: 0,
              msg: response.data.msg,
              showSnackBar: true
            });
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.$emit("stepper", {
            updateData: false,
            nextComponent: 1,
            msg: "Server Unreachable",
            showSnackBar: true
          });
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
</style>